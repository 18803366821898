import React from 'react';
import SEO from "../components/seo"
import Layout from "../components/layout"
import logoBlack from '../images/logo-black.jpg';

const AboutPage = () => (
  <Layout>
    <SEO title="About" />
    <div className="about-background sma:h-64"></div>
    <section className="lg:flex container mx-auto mt-16 my-20">
      <div className="w-full md:w-4/5 py-6 md:m-10">
        <img src={logoBlack} className="h-32 w-48 m-auto"/>
      </div>
      <div className="w-full md:w-4/5 lg:m-auto sm:mt-10">
        <h1 className="text-4xl text-hover">About Cymetrix Software</h1>
        <div className="w-full bg-gray-900 h-px"></div>
        <span className="mt-4 block text-gray-800 text-lg">Cymetrix Software was founded in 2016 based on one principle “simplifying doing business with innovative IT solutions”.</span>
        <p className="leading-relaxed mt-2 text-gray-700 font-thin">As Salesforce Partners, Cymetrix provides custom solutions using Salesforce platform, helping small & mid-sized organisations improve their business performance as they grow. We have a passionate team of Salesforce consultants, architects, developers and testers who are always ready to walk that extra mile and deliver the solutions best suited for the business. Cymetrix is led by industry veterans with extensive IT industry experience and delivering solutions to global clients. The best of the class global experience of our founders reflect in the delivery process rigour. The company's consulting service portfolio includes:</p>
        <div className="leading-loose mt-2 text-gray-700 font-thin">Salesforce Consulting <br />
          Spend Analysis <br />
          Business Intelligence<br />
          Cyber Security<br />
          IT Consulting
        </div>
        <p className="mt-10 block text-gray-700 text-lg font-thin">Headquartered in India, Cymetrix has offices in Mumbai and San Francisco, USA</p>
      </div>
    </section>
  </Layout>
)

export default AboutPage;